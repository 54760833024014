import React from "react";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { ITopHeaderUserMenu } from "../../../models/TopHeader";

const UserMenuComponent = ({
  t,
  anchorEl,
  firstName,
  lastName,
  avatarSrc,
  isCandidate,
  desktop,
  onOpenProfile,
  onOpen,
  onClose,
  onLogout,
}: ITopHeaderUserMenu) => (
  <Stack direction={"row"} alignItems={"center"} spacing={2} maxHeight={"40px"}>
    {desktop && (
      <Typography variant={"subtitle2"} fontWeight={500}>
        {firstName} {lastName}
      </Typography>
    )}
    <IconButton onClick={onOpen} size="small" sx={{ padding: 0 }}>
      <Avatar src={avatarSrc}>
        {avatarSrc
          ? ""
          : firstName && lastName
          ? (firstName[0] + lastName[0]).toUpperCase()
          : ""}
      </Avatar>
    </IconButton>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {!isCandidate && (
        <MenuItem onClick={onOpenProfile}>
          <Typography variant="subtitle2">
            {t("userSettings.user_settings")}
          </Typography>
        </MenuItem>
      )}
      <MenuItem onClick={onLogout}>
        <Typography variant="subtitle2">{t("userSettings.logout")}</Typography>
      </MenuItem>
    </Menu>
  </Stack>
);

export default UserMenuComponent;
