// chart configs
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";
import { ApexOptions } from "apexcharts";
import { RangeType } from "rsuite/esm/DateRangePicker/types";
import { TFunction } from "i18next";
import { translationEn } from "../../utils/translationEn";

export const NOAChartOptions: ApexOptions = {
  chart: { toolbar: { show: false }, zoom: { enabled: false }, type: "area" },
  dataLabels: { enabled: false },
  stroke: { curve: "straight" },
  yaxis: { min: 0, stepSize: 1 },
  fill: {
    type: "gradient",
    gradient: {
      type: "horizontal",
      colorStops: [
        { color: "#FF6E7F", offset: 0, opacity: 0.5 },
        { color: "#BFE9FF", offset: 100, opacity: 0.5 },
      ],
    },
  },
};

export const SOHChartOptions = ({
  isMultiple,
}: {
  isMultiple: boolean;
}): ApexOptions => ({
  chart: { height: 350, type: "radialBar" },
  legend: {
    show: true,
    position: "bottom",
    formatter(legendName: string, opts?: any): string {
      const series = opts.w.config.series;
      return `${legendName} ${series[opts.seriesIndex]}`;
    },
  },
  plotOptions: {
    radialBar: {
      dataLabels: {
        name: {
          fontSize: "22px",
        },
        value: {
          fontSize: "16px",
          formatter: (res) => res.toString(),
        },
        total: {
          show: isMultiple,
          label: localStorage.getItem("lang") === "en_US" ? "Total" : "Gesamt",
          formatter: ({ config }) => {
            const sum = config.series.reduce(
              (acc: number, item: number) => acc + item,
              0,
            );
            return sum.toString();
          },
        },
      },
    },
  },
});

export const ADSChartOptions = (jobs: any, stages: string[]): ApexOptions => ({
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    toolbar: { show: false },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        total: {
          enabled: true,
          offsetX: 0,
        },
      },
    },
  },
  stroke: {
    width: 1,
    colors: ["#fff"],
  },
  tooltip: {
    y: {
      formatter: (val: number) => `${val} days`,
    },
    x: {
      formatter: (val: number, { dataPointIndex, seriesIndex }) => {
        const targetStage = stages[seriesIndex];
        const { quantity = 0 } =
          jobs?.[dataPointIndex]?.sumStat[targetStage] || {};
        return `<div><div>${val}</div><div>Applicants ${quantity}</div></div>`;
      },
    },
  },
  fill: {
    colors: ["#0E63AC", "#178CF2", "#5FB2FA", "#C6E5FF"],
    opacity: 1,
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    markers: {
      fillColors: ["#0E63AC", "#178CF2", "#5FB2FA", "#C6E5FF"],
    },
  },
});

export const predefinedRanges = (t: TFunction): RangeType[] => [
  {
    label: t("analytics.today") as string,
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: t("analytics.yesterday") as string,
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: t("analytics.thisWeek") as string,
    value: [
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      endOfWeek(new Date(), { weekStartsOn: 1 }),
    ],
    placement: "left",
  },
  {
    label: t("analytics.last7Days") as string,
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: t("analytics.thisMonth") as string,
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: t("analytics.lastMonth") as string,
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
];

export const getPerformedName = ({
  name,
  path,
}: {
  name: string;
  path: "defaultStatuses" | "source";
}) =>
  [...Object.keys(translationEn.analytics[path])].includes(name)
    ? `analytics.${path}.${name}`
    : name;
