import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { IApplicationPage } from "../../models/ApplicationPage";
import { getCandidateState } from "../../store_new/selectors/Candidate";
import ApplicationPageComponent from "./component";
import {
  getActivityLog,
  getCurrentApplication,
  getJobApplicationsLoadingState,
  getSelectedJobOwners,
  getJobApplicationsOwnersLoadingState,
} from "../../store_new/selectors/Applications";
import {
  fetchActivityLog,
  fetchSelectedJobApplicationAnswers,
  fetchSelectedJobApplications,
  fetchSelectedJobOwners,
  requestApplicationUnlock,
  setJobWorkflow,
} from "../../store_new/reducers/Applications";
import { getMessagesState } from "../../store_new/selectors/Messages";
import {
  getLatestMessages,
  setActiveUser,
} from "../../store_new/reducers/Messages";
import ApplicantsListDialog from "../../components/dialogs/MessageDialog/ApplicantsListDialog";
import {
  fetchCandidateApplications,
  setCandidateJobUrlKey,
  unlockCandidateApplication,
  updateIsCandidateState,
} from "../../store_new/reducers/Candidate";
import { setCandidate } from "../../slices/Candidate";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import { fetchJobDetails } from "../../store_new/reducers/SingleJob";
import { getWorkflowState } from "../../store_new/selectors/Workflow";
import { IWorkflow } from "../../models/Workflow";
import { addMessage } from "../../slices/NotificationSlice/GlobalNotificationSlice";
import RequestDataDialog from "./RequestDataDialog";

const ApplicationPage_new = ({ desktop }: IApplicationPage) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { key: url_key, job_id } = useParams();
  const { firstName, isAgency } = useSelector(getCurrentUserData);
  const { latestMessages } = useSelector(getMessagesState);
  const { isLoading, isCandidate } = useSelector(getCandidateState);
  const { workflows } = useSelector(getWorkflowState);
  const isSelectedJobApplicationsLoading = useSelector(
    getJobApplicationsLoadingState,
  );

  const selectedJobOwners = useSelector(getSelectedJobOwners);
  const activityLog = useSelector(getActivityLog);
  const currentApplication = useSelector(getCurrentApplication);
  const isOwnersLoading = useSelector(getJobApplicationsOwnersLoadingState);

  const [isNewMessageDialogOpen, setIsNewMessageDialogOpen] = useState(false);

  const isAgencyOrCandidate = isAgency || isCandidate;

  const isApplicationLoading =
    isLoading ||
    isSelectedJobApplicationsLoading ||
    isOwnersLoading ||
    !currentApplication ||
    !firstName;

  const messagesExist = useMemo(() => {
    if (latestMessages && currentApplication)
      return latestMessages.find(
        (item) => item.application_id == currentApplication?.id,
      );
  }, [latestMessages, currentApplication]);

  const handleOnMessage = () => {
    if (messagesExist && currentApplication) {
      dispatch(
        setActiveUser({
          job_id,
          candidate_id: parseInt(currentApplication.candidate_id as string),
          recruiter_id: parseInt(currentApplication.candidate_id as string),
          application_id: currentApplication.id,
        }),
      );
      navigate("/messages");
    } else {
      setIsNewMessageDialogOpen(true);
    }
  };

  const handleOnCloseDialog = () => {
    setIsNewMessageDialogOpen(false);
  };

  const handleRequestUnlockApplication = () => {
    if (!currentApplication?.is_unlock_requested) {
      dispatch(
        requestApplicationUnlock({
          applicationId: currentApplication?.application_id as string,
          callback: () =>
            dispatch(
              fetchSelectedJobApplications({
                job_id: job_id as string,
                candidateUrlKey: url_key as string,
              }),
            ),
        }),
      );
    }
  };

  const onSuccessUnlockApplication = () => {
    dispatch(fetchCandidateApplications());
    dispatch(
      addMessage({
        type: "success",
        title: t("candidate.confirmed"),
      }),
    );
  };

  const handleConfirmOutdatedApplication = () => {
    if (currentApplication?.is_unlock_requested) {
      dispatch(
        unlockCandidateApplication({
          applicationId: currentApplication?.id as number,
          href: currentApplication?.unlock_hash as string,
          callback: () => onSuccessUnlockApplication(),
        }),
      );
    }
  };

  useEffect(() => {
    if (currentApplication?.job_url_key && isCandidate === false) {
      dispatch(
        fetchJobDetails({
          jobUrlKey: currentApplication?.job_url_key,
        }),
      );
    }
    if (currentApplication?.workflow_id) {
      const findWorkflow = workflows?.find(
        (workflow: IWorkflow) => workflow.id == currentApplication.workflow_id,
      );
      dispatch(setJobWorkflow(findWorkflow as IWorkflow));
    }
  }, [currentApplication, workflows]);

  useEffect(() => {
    if (job_id) {
      // recruiter routine
      dispatch(updateIsCandidateState({ isCandidate: false }));
      if (!currentApplication && url_key) {
        dispatch(
          fetchSelectedJobApplications({ job_id, candidateUrlKey: url_key }),
        );
      }

      if (!latestMessages && !isAgency && firstName) {
        dispatch(getLatestMessages());
      }
    } else if (url_key) {
      dispatch(setCandidateJobUrlKey({ jobUrlKey: url_key }));
    }
  }, []);

  useEffect(() => {
    if (currentApplication?.job_url_key && !selectedJobOwners) {
      if (!isAgency) {
        dispatch(
          fetchActivityLog(currentApplication?.application_id as string),
        );
      }
      dispatch(
        fetchSelectedJobOwners(currentApplication?.job_url_key as string),
      );
    }

    if (currentApplication) {
      dispatch(
        setCandidate({
          ...currentApplication,
          company_title: currentApplication.job?.title,
        }),
      );
    }
    // TODO refactor within Message refactoring
  }, [currentApplication]);

  useEffect(() => {
    if (
      !isCandidate &&
      currentApplication?.application_id &&
      currentApplication.job_id
    ) {
      dispatch(
        fetchSelectedJobApplicationAnswers({
          jobId: currentApplication.job_id,
          applicationId: currentApplication.application_id,
        }),
      );
    }
  }, [isCandidate, currentApplication]);

  return (
    <>
      <ApplicationPageComponent
        t={t}
        desktop={desktop}
        activityLog={activityLog}
        isCandidate={isCandidate}
        currentApplication={currentApplication}
        isAgencyOrCandidate={Boolean(isAgencyOrCandidate)}
        isLoading={isApplicationLoading}
        onConfirmOutdatedApplication={handleConfirmOutdatedApplication}
        onRequestUnlockApplication={handleRequestUnlockApplication}
        handleOnMessage={handleOnMessage}
      />
      <ApplicantsListDialog
        t={t}
        isOpen={isNewMessageDialogOpen}
        title={t("messages_page.new_conversation")}
        onClose={handleOnCloseDialog}
      />
      <RequestDataDialog />
    </>
  );
};

export default ApplicationPage_new;
