export const translationEn = {
  // navigation
  navigation: {
    analytics: "Analytics",
    applications: "Applications",
    settings: "Settings",
    company_profiles: "General",
    discover: "Discover",
    inventory: "Inventory",
    bookings: "Bookings",
    create_job_ad: "Create job ad",
    messages: "Messages",
    profile: "Profile",
    customers: "Customers",
    discover_all_products: "Discover all products",
    workflow: "Workflows",
  },

  //dashboard
  dashboard: {
    firstSteps: {
      title: "Your first steps",
      stepOne: "Create and activate your account",
      stepTwo: "Upload your company logo",
      stepThree: "Edit your company page",
      stepFour: "Add your LinkedIn company profile",
      stepFive: "Add your Xing company profile",
      stepSix: "Create your first job ad",
    },
    support: {
      title: "Support",
      ctaBtn: "Get support now",
      footerText: "If you have any questions, send an email to",
    },
    jobs: {
      title: "Your jobs",
      titleBtn: "View all",
      createBtn: "Create a job",
      applications: "Applications",
      emptyList: "You don't have any jobs yet",
    },
    applications: {
      title: "Your applications",
      titleBtn: "View all",
      emptyList: "You don't have any applications yet",
    },
  },

  // update company informatio
  update_company_information: {
    title: "Update your company data",
    textOne: "Please update your company profile with your address data,",
    textTwo: "to use Karriera with all functionalities.",
    country: "Country",
    city: "City",
    postcode: "Postcode",
    street: "Street",
    number: "Number",
    reference_number: "Reference number",
    keywords: "Keywords",
    success_snackbar: "Your address has been successfully updated.",
  },

  // create job dialog first step
  create_job_first_step: {
    preview: "Preview",
    save_as_draft: "Save as draft",
    save_draft: "Save draft",
    continue_editing: "Continue editing",
    save_draft_first_text:
      "Would you like to save your job ad creation as a draft?",
    save_draft_second_text: `Your progress will be saved and accessible through the job list on the “draft” tab, for future editing.`,
    create_job_ad: "Create job",
    describe_job: "Describe job",
    specify_job: "Specify job",
    application_process: "Application process",
    job_title: "Job title",
    job_title_placeholder: "Job title (m/w/d)",
    introduction_title: "Introduction Title",
    introduction_title_placeholder: "About us",
    introduction: "Introduction",
    introduction_placeholder:
      "Briefly describe what your company does and why you are an attractive employer.",
    tasks_title: "Tasks Title",
    tasks: "Tasks",
    tasks_placeholder:
      "Describe potential tasks and responsibilities so that potential applicants understand what makes this position attractive.",
    requirements_title: "Requirements Title",
    requirements_title_placeholder: "Qualifications",
    requirements: "Requirements",
    requirements_placeholder:
      "Describe the requirements of the job to give applicants a good impression of the position.",
    benefits: "Benefits",
    benefits_title: "Benefits Title",
    benefits_placeholder: "Benefits",
    contact_information_title: "Contact Information Title",
    contact_information: "Contact information",
    contact_information_placeholder: "Contact information",
    video_placeholder: "Video URL",
    upload_photo: "Upload photo {{number}}",
    upload_logo: "Upload logo",
    upload_background_image: "Upload background picture",
    title_color: "Title color",
    background_color: "Background color",
    photo_size: "PNG, JPG, up to 5MB",
  },

  // create job dialog second step
  create_job_second_step: {
    jobTypes: {
      "on-site": "Onsite",
      hybrid: "Hybrid",
      remote: "Remote",
    },
    location: "Location",
    general_information: "General information",
    contract_type: "Contract type",
    field_of_work: "Field of work",
    seniority: "Seniority",
    job_type: "Job type",
    additional_information: "Additional information",
    reference_number: "Reference number",
    date_of_publication: "Date of publication",
    assign_job_to: "Assign job to",
    keywords: "Keywords",
    keywords_placeholder: "Keyword 1, Keyword 2, Keyword 3",
    notes: "Notes",
    salary: "Salary",
    minimum_salary: "Minimum salary",
    minimum_salary_placeholder: "Min salary",
    maximum_salary: "Maximum salary",
    maximum_salary_placeholder: "Max salary",
    currency: "Currency",
    interval: "Interval",
    qualification_level: "Qualification level",
  },

  // create job dialog third step
  create_job_third_step: {
    individualize_application_process: "Individualize application process",
    basic_questions: "Basic questions",
    phone_number: "Phone number",
    salary_expectation: "Salary expectation",
    earliest_start_date: "Earliest start date",
    driving_licenses: "Driving licenses",
    current_position: "Current position",
    highest_degree: "Highest degree",
    language_skills: "Language skills",
    skills: "Skills",
    documents: "Documents",
    additional_documents: "Additional documents (max. 4 documents)",
    additional_questions: "Additional questions",
    profile_photo: "Profile picture",
    question: "Question",
    answer: "Answer",
    answer_type: "Answer type:",
    add_question: "Add question",
    free_text: "Free text",
    yes_no: "Yes / No",
  },

  // create job dialog fourth step
  create_job_fourth_step: {
    search_job_board: "Search job board",
    industry: "Industry",
    country: "Country",
    productName: "Product name",
    duration: "Duration ",
    number_selected_products: "Product(s)",
    products: "Products",
    succes_heading: "Your job ad has been published successfully.",
    succes_subheading_one:
      "Your job ad has been published on free platforms that already give you good visibility.",
    succes_subheading_two:
      "Use premium job boards to further increase the number and quality of talent.",
    add_package: "Add package",
    package_added: "Package added",
    select_product: "Select product",
    price: "Price",
    create_job_and_publish_title: "Create a job and publish",
    create_job_and_publish_text: "You can create a job to book your product.",
    create_new_job: "Create new job",
    select_and_publish_job_title: "Select a job and publish",
    select_and_publish_job_text: "You can select a job to book your product.",
    show_all_packages: "Show all packages",
    no_description: "No description",
    packages: "Packages",
    days: "days",
    filterDurations: {
      upTo30: "Up to 30 days",
      upTo60: "Up to 60 days",
      upTo90: "Up to 90 days",
      moreThan90: "More than 90 days",
    },
    keywords: "Keywords",
    reference_number: "Reference number",
    success_title: "You have successfully created the job",
    starting_from: "Starting from",
  },

  // create job dialog fifth step
  create_job_fifth_step: {
    confirmation: "Confirmation",
    selected_products: "Selected products",
    summary: "Summary",
    original_price: "Original price",
    discount: "Discount",
    sub_total: "Sub-total",
    total: "Total",
    agency_price: "Agency price",
    your_total: "Your total",
    customer_total: "Customer total",
    termsFirst: "By confirming your order, you agree to the",
    termsSecond: "terms and conditions",
    termsThird: "and the",
    termsFour: "privacy",
    termsFive: "policy of Karriera.",
    place_your_order: "Place your order",
    no_result: "No results?",
    using_filters: "Are you using filters?",
    reset_filters: "Reset filters",
    snackbar_title: "You published the job successfully.",
    margin: "Margin",
  },

  // cart
  cart: {
    title: "Shopping cart",
    empty_title: "Your shopping cart is empty",
    empty_text_one:
      "Looks like you haven't added anything to your shopping cart yet.",
    empty_text_two: "Choose now suitable products for your job.",
    empty_button: "Select products now",
    checkout: "Proceed to checkout",
  },

  // additional questions
  additional_questions: {
    confirmation_title: "We have received your application.",
    confirmation_subtitle:
      "In order to better evaluate your application, we would like to ask you for additional information.",
    phone_number: "Please enter your telephone number",
    location: "Please enter your current place of residence",
    salary_expectation: "Please add your salary expectation",
    start_date: "Please specify your earliest possible start date",
    start_date_placeholder: "Select date",
    current_profession: "What is your current professional status",
    degree: "What is your highest school-leaving or vocational qualification?",
    driving_licenses: "Please name your driving licence classes",
    driving_licenses_placeholder: "Select licenses",
    language: "What language skills do you have",
    language_placeholder: "Select language",
    language_placeholder_two: "Level",
    skills: "Please add your skills",
    skills_placeholder: "Add skills",
    documents: "Please add your documents",
    documents_placeholder: "Upload documents",
    profile_picture: "Please add a profile picture",
    profile_picture_placeholder: "Upload profile picture",
    add_language: "Add language",
    type_something_placeholder: "Type something",
    city_no_options:
      "We could not find your city. Please select a larger city near you.",
  },

  //  button
  button: {
    cancel: "Cancel",
    next: "Next",
    save: "Save",
    secondCancel: "Cancel",
    continue: "Continue",
    no_thanks: "No thanks",
    skip: "Skip",
    submit: "Submit information",
    remove: "Remove",
  },

  // jobs
  jobs: {
    navigation: {
      all_jobs: "All Jobs",
      archived: "Archived",
    },
  },

  // verification resend ...
  verification: {
    title: "Verify your email",
    email_subtitle_one:
      "To confirm your email address please click on the link",
    email_subtitle_two: "in the email we sent you.",
    subtitle_one:
      "We’ve sent the verification link to your email. It expires in 1 hour.",
    subtitle_two:
      "Your application will be submitted once you verify your email address.",
    resend: "Resend email",
  },

  // job list
  joblist: {
    search_placeholder: "Search by job title",
    search_by_company_placeholder: "Search by company name",
    continue_editing: "Continue editing",
    location_placeholder: "Location",
    seniority_placeholder: "Seniority",
    seniority: "Seniority",
    empty_button: "Create a job",
    reset_filters_button: "Reset filters",
    empty_first_text: "You do not have a job yet.",
    empty_first_text_filters_exists:
      "Apparently, no matching results can be found for your filter.",
    empty_second_text: "Create your first job ad!",
    no_archived_job_title: "You do not yet have any archived jobs.",
    no_draft_job_title: "You do not yet have any drafts yet.",
    title: "Title",
    export: "Export",
    archive: "Archive",
    add: "Add application",
    promote: "Promote",
    activate: "Activate",
    duplicate: "Duplicate",
    duplicate_dialog_title: "Duplicate job ad",
    duplicate_dialog_subtitle: "Would you like to duplicate this job ad?",
    duplicate_snackbar_title:
      "You have successfully duplicated the job and saved it under Drafts.",
    save_draft_snackbar_title:
      "You have successfully saved the draft. You can find this draft under Drafts and edit it at any time.",
  },

  // application complete
  application_complete: {
    title: "Your application was successful!",
    subtitle:
      "Your application has been successfully submitted to the company.",
    dashboard_button: "Check application status",
  },

  // applications
  applications: {
    search_placeholder: "Search by name",
    job_placeholder: "Select job",
    status_placeholder: "Select state",
    name: "Name",
    applied_to: "Applied to",
    date_applied: "Date applied",
    download_cv: "Download CV",
    empty_first_text: "You don’t have any applications yet",
    empty_second_text:
      "Publish your job on our premium job boards to receive more applications.",
    no_bookings_title:
      "Publish the job now on premium portals and receive up to 3x more applicants.",
    empty_button: "Publish now",
    delete_application: "Delete application",
    delete_application_text:
      "If you delete the application, it will be removed from Karriera so that you no longer have access to the data.",
    delete_multiple_application_text:
      "If you delete the applications, they will be removed from Karriera so that you no longer have access to the data.",
    delete_candidate_application_text:
      "If you withdraw your application, it will be removed from Karriera so that Plantura's recruiters can no longer access it. Please note that they may have already downloaded your data. Please contact the company directly to request the deletion of your data from the system.",
    delete_application_note: "This action cannot be undone.",
    delete_application_snackbar: "The application was successfully deleted.",
    withdraw_application_snackbar:
      "You have successfully withdrawn your application.",
    delete_multiple_application_snackbar:
      "If you delete the applications, they will be removed from Karriera so that you no longer have access to the data.",
    withdraw_application: "Withdraw application",
    withdraw_application_text:
      "If you withdraw your application, it will be removed from Karriera so that Plantura's recruiters can no longer access it. Please note that they may have already downloaded your data. Please contact the company directly to request the deletion of your data from the system.",
    gdpr: "In accordance with the provisions of the General Data Protection Regulation, the applicant's data will no longer be displayed unless you ask for permission.",
    information_not_available: "Information not available",
    request_permission: "Request permission",
    persmissions_requested: "Persmissions requested",
    applicationsOlderTitle: "The application is older than",
    applicationsOlderText:
      "In accordance with the data protection regulations, the applicant's data will no longer be displayed unless you ask for permission.",
  },

  // Candidate
  candidate: {
    applied_on: "Applied on ",
    for_the_job: " for the job ",
    personal_information: "Personal information",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    location: "Location",
    country: "Country",
    documents: "Documents",
    additional_information: "Additional information",
    show_additional_questions: "Show additional questions",
    hide_additional_questions: "Hide additional questions",
    send_direct_message: "Send a message now to ",
    send_message: "Send message",
    editing_note: "Editing note",
    reply: "Reply",
    write_a_note: "Write a note",
    collapse_all: "Collapse all",
    comments: "comments",
    are_you_sure_delete_note: "Are you sure you want to delete this note?",
    delete_note: "Notiz löschen?",
    no_notes: "There are no new notes",
    delete_message_snackbar_title: "Note successfully deleted",
    no_documents: "There are no documents",
    document_upload_success: "The document has been successfully uploaded",
    document_upload_error:
      "There was an error uploading the document. Check the size (max. 5 MB) and try again.",
    activity_log: "Activity log",
    send_message_to_first_text: "sent a message to",
    send_message_to_second_text: "",
    received_message_from_first_text: "Received a message from",
    received_message_from_second_text: "",
    changed_status_from_first_text: "changed status from",
    changed_status_from_second_text: "",
    changed_status_to: "to",
    added_to_talent_pool:
      "was moved from {{jobTitle}} to the talent pool {{talentPool}}",
    added_to_job_from_talent_pool:
      "was added to the job {{jobTitle}} by the talent pool {{talentPool}}",
    added_note: "added a note",
    uploaded_document: "uploaded a document",
    answered_additional_questions_to_first_text:
      "answered additional questions to",
    answered_additional_questions_to_second_text: "",
    update_additional_questions_first_text: "Additional questions about",
    update_additional_questions_second_text: "were answered",
    applied_to_first_text: "applied to",
    applied_to_second_text: "",
    on_date: "on",
    by_candidate: "by",
    addProfile: "Add profile",
    additionalInfoTooltip: {
      firstStr: "This data is also visible to applicants.",
      secondStr: "If you would like to add private information, use the notes.",
    },
    persmissions_requested: "Permission for your data has been requested.",
    confirm_request: "Confirm request",
    confirm_request_text:
      "We would like to retain your application data for future job opportunities. After 180 days, we will ask for your consent again to continue storing your data.",
    confirmed: "Confirmed",
  },
  candidate_questions: {
    salary_expectation: "What is your salary expectation?",
    earliest_start_date: "What is your earliest start date?",
    current_professional_status: "What is your current professional state?",
    highest_degree: "What is your highest degree?",
    driving_licenses: "What drivers licenses do you have?",
    language: "Which language skills do you have?",
    skills: "Which skills do you have?",
    no_answer: "No answer",
    tooltip_text: "Question is active",
    editAdditionalInfo: "Edit Additional Information",
  },

  // single job candidates
  single_job: {
    applications: "Applications",
    basic_job_boards: "Basic job boards",
    premium_job_boards: "Premium job boards",
    job_details: "Job details",
    date_created: "Date created",
    applied_on: "Applied on",
    edit: "Edit",
    bookings: "Bookings",
    in_status_for: "In status for {{numberOfDaysInStatus}} day(s)",
  },

  // job details
  job_details: {
    job_description: "Job description",
    contact: "Contact",
  },

  // basic job boards - table
  basic_job_board: {
    title: "Job board",
    date_published: "Date published",
    published_at: "Published at",
    days_remaining: "Days remaining",
    company_profile_connect: "Connect {{title}}",
    upload_header_image: "Upload header image",
    product_name: "Product name",
    remaining: "Remaining",
    clicks: "Clicks",
    of: "of",
    days: "days",
  },

  // add company profile dialog
  addCompanyProfileDialog: {
    title: "Link your {{title}} company profile",
    description:
      "To ensure that your vacancies are advertised correctly, give Enter the URL of your page on {{title}}.",
  },

  // user settings menu
  userSettings: {
    user_settings: "User settings",
    logout: "Logout",
    personal_information: "Personal information",
    firstname: "First name",
    lastname: "Last name",
    phone_number: "Phone number",
    email: "Email",
    language: "Language",
    german: "German",
    english: "English",
    change_password: "Change password",
    current_password: "Current password",
    new_password: "New password",
    confirm_password: "Confirm new password",
    password_not_match: "The passwords do not match.",
    upload_profile_picture: "Upload profile picture",
    select_picture: "Select picture",
    select_picture_formats: "Supported formats: JPG, PNG",
    incorrect_password: "Incorrect password",
    connect_account: "Connect your account",
    snackbar_title: "Your settings were saved successfully.",
    expected_dimensions: "Expected dimensions",
    or: "or",
    min_width: "Min width",
    change_password_snackbar: "Your password has been changed successfully.",
    no_password_snackbar:
      "Keep an eye on your applications. To make logging in easier, set a password.",
    create_password: "Create password",
  },

  // team members
  team_members: {
    pending: "Pending",
    joined: "Joined",
    invite: "Invite",
    invite_team: "Invite team",
    invite_team_members: "Invite team members",
    choose_team_members: "Choose team members",
    date_invited: "Date invited",
    role: "Role",
    invite_title: "Invite team members",
    email: "Email",
    search_placeholder: "Search by Email",
    invite_text: "You have no pending invitations to other team members",
    invite_button: "Invite now",
    delete_title: "Remove team member",
    delete_text: "Do you want to remove this member from your team?",
    invalid_email: "Invalid email address",
    invite_snackbar_title: "Your invitation was sent successfully.",
    no_team_members_text: "There are no team members to invite",
    resend_invite: "Resend invite",
  },

  // job widget
  job_widget: {
    title: "Job widget",
    embed_code: "Embed code",
    copy: "Copy",
  },

  // company page
  company_page: {
    employees: "employees",
    open_position: "All open positions at {{company_name}}",
    currently_open: "We currently have {{total}} open positions",
    departments: "All departments",
    location: "All locations",
    no_available_jobs: "No available jobs",
    no_positions: "We don't have any open positions at the moment",
    show_all: "Show all",
    other_open_positions: "Other open positions",
    unknown: "Unknown",
    website: "Website",
    no_company_title:
      "It seems like the company page you are looking for is currently inactive.",
    no_company_subtitle_one:
      "We apologize for any inconvenience this may have caused. If you believe this is a mistake,",
    no_company_subtitle_two:
      "or if you would like more information, please contact our support team at",
    no_company_thank_you: "Thank you for your understanding.",
    back_to_homepage: "Back to homepage",
    more: "more",
    show_less: "Show less",
    show_more: "Show more",
  },

  // company settings
  company_settings: {
    title: "Company settings",
    company_name: "Company name",
    upload_logo: "Upload logo",
    technical_information: "Technical information",
    api_token: "API token",
    view: "View",
    link_to_website: "Link to website",
    number_of_employees: "Number of employees",
    industry: "Industry",
    about_us: "About us",
    copy_message: "copied to clipboard",
    preview_company_page: "Preview company page",
    switch_text_on: "Your career page is visible for applicants",
    switch_text_off: "Your career page is not visible for applicants",
    career_page: "Career page",
    snackbar_title: "Your settings were saved successfully.",
    link_company_profile: "Link company profile",
    company_color: "Company color",
    color: "Color",
  },

  // Login page
  login: {
    welcome_to_karriera: "Welcome to Karriera",
    please_login: "Please log in to your account",
    e_mail: "Email",
    e_mail_example: "name@example.de",
    password: "Password",
    your_password: "Your password",
    forgot_password: "Forgot Password?",
    log_in: "Log in",
    you_dont_have_acc: "You don’t have an account yet? ",
    sign_up: "Sign up",
    with_karierra: "With Karriera",
    your_design: "You design optimized job ads",
    reach_more: "You reach more candidates",
    hire: "You hire collaboratively",
    password_changed_title: "Your password has been changed successfully",
    password_changed_text:
      "Please log in to your account using the button below.",
    sign_in: "Sign in",
  },

  // Login page, register
  register: {
    register_as_a_company: "Register as a company",
    set_your_company_profile: "Set up your company profile",
    placeholder_company_name: "Example GmbH",
    placeholder_company_website: "company.com",
    company_name: "Company name",
    company_website: "Company website",
    message: "Field is required",
    select_industry: "Select your industry",
    industry: "Industry",
    number_of_employees: "Number of employees",
    select_number_of_employees: "Select number of employees",
    continue: "Continue",
    alredy_have_account: "Already have an account? ",
  },

  recruiter: {
    register_as_recruiter: "Register as a recruiter",
    set_your_recruiter_profile: "Set up your recruiter profile",
    first_name: "First name",
    last_name: "Last name",
    phone_number: "Phone number",
    email_placeholder: "example@company.com",
    privacy_policy_1: "I agree to the ",
    t_and_c: "T&C",
    privacy_policy_2: " and the data processing agreement and have read the ",
    privacy: "Privacy policy",
    finish: "Finish",
  },

  // apply change
  apply_page: {
    password: "Password",
    forgot_password: "Forgot password?",
    login: "Login",
    send_magic_link: "Send me a magic link",
    error_message:
      "There is already an account registered with this email. Please login before you apply.",
    apply_now: "Apply now",
    upload_cv: "Upload CV",
    terms_first_text: "I agree to the",
    terms_second_text: "T&C",
    terms_third_text: "and the data processing agreement and have read the",
    terms_forth_text: "privacy policy",
    terms_fifth_text: ".",
    apply_text: "Fill out the fields below and apply now",
    no_job_text: "This job is no longer available.",
    share_job: "Share this job",
  },

  // candidate dashboard
  candidate_dashboard: {
    subtitle: "You have applied for the following jobs",
    show_job: "Show job",
    open_drafts: "Open drafts",
    no_jobs: "You have not applied for a current position.",
  },

  // reset password page (request reset password)
  reset_password_page: {
    title: "Reset password",
    text: "Please enter your email address below",
    button: "Send Link",
    link: "Back to website",
    check_inbox: "Check your inbox",
    email_not_exist: "This email does not exist in the system",
    email_exist_text:
      "If there is an account associated with the email address provided, we",
    email_exist_second_text:
      "will send you an email with instructions on how to reset your password.",
    check_spam: "Don't forget to check your spam folder.",
    new_password_text: "Please confirm your new password",
    new_password: "New password",
    confirm_password: "Confirm password",
  },

  // limit dialog
  limit_dialog: {
    usePro: "Use Karriera Pro now",
    title: "Find a suitable plan for your company",
    text: "Would you like to customise your process? We can customise your Karriera account to perfectly suit your needs.",
    button: "Request now",
    confirmation_title: "Your request has been sent",
    confirmation_text:
      "Thank you for reaching out to us! Someone from our team will get back to you shortly.",
  },

  // archive job dialog
  archive_job_dialog: {
    title: "Are you sure you want to archive your job?",
    t1: "Job will be no longer online on your career page or job widget",
    t2: "FREE multi-posting will be deactivated within the next 12 hours",
    t3: "Candidates will not be able to submit new applications",
    t4: "Products with a remaining lifespan expire and cannot be reactivated for this job",
    t5: "You will still be able to manage candidates who already applied",
    t6: "You can always reactivate this job later to get more applications",
    activate_title: "Are you sure you want to reactivate the job?",
    t7: "The free publishing will be reactivated within the next 12 hours",
    t8: "The job will be displayed on your career page and in the job widget",
    cancel: "Leave the job online",
    delete: "Archive this job",
  },

  delete_job_dialog: {
    title: "Are you sure you want to permanently delete this job?",
    body: "The job currently has the status archived and can be reactivated at any time. If the job is permanently deleted, this process can no longer be undone.",
    cancel: "Cancel",
    delete: "Delete job",
  },

  // magic link confirmation page
  magic_link_confirmation_page: {
    title: "Check your inbox",
    text1: "If an account is associated with the email address provided,",
    text2: "we will send you instructions on logging in and applying.",
    text3: "Don't forget to check your spam folder.",
    back_to_job: "Back to job",
  },

  // messages page
  messages_page: {
    no_messages: "You don’t have any messages yet.",
    applied_to_job_one: "Applied to",
    on: "on",
    show_job_details: "Show job details",
    at: "at",
    delete_message_dialog_title: "Delete message",
    delete_message_dialog_text: "Are you sure you want to delete the message?",
    new_message_placeholder: "Write message",
    edit_dialog_title: "Save changes to the message",
    edit_dialog_text:
      "Do you want to save the changes you have made and continue?",
    discard_dialog_title: "Discard changes to the message",
    discard_dialog_text:
      "Do you want to discard the changes you made and continue?",
    edited: "Edited",
    new_chat: "New chat",
    new_conversation: "New chat",
    delete_message_snackbar_title: "Message successfully deleted",
    show_profile: "Show profile",
    mark_as_read: "Mark as read",
    mark_as_unread: "Mark as unread",
    from: "From",
  },

  // status
  status: {
    new: "New",
    in_progress: "In Progress",
    review: "In Progress",
    hired: "Hired",
    rejected: "Rejected",
    active: "Active",
    inactive: "Inactive",
    archived: "Archived",
    draft: "Draft",
  },

  // customers
  customers: {
    company: "Company",
    invite: "Invite customers",
    no_customers_title: "The list is empty, you have no customers yet.",
    no_customers_subtitle_one: `Through the "Invite Customers" button, you can invite customers.`,
    no_customers_subtitle_two: `If you already have customers and want to synchronize them here, please click on the "Sync Customers" button.`,
    no_customers_sync: "Synch Customers",
    no_customers_invite: "Invite customers",
    sync: "Sync customers",
    select_customer: "Select customer",
    select_customer_subtitle:
      "Select a customer for whom a job should be created.",
    no_selected_customer: "Please select a customer to continue.",
    connected_with: "Connected with",
    since: "since",
    disconnect: "Disconnect",
    company_name: "Company name",
    contact_person_firstname: "Contact person first name",
    contact_person_lastname: "Contact person last name",
    contact_email: "Contact email",
    contact_phone: "Contact phone",
    disconnect_dialog_title: "Remove connection",
    disconnect_dialog_body: "Are you sure you want to disconnect?",
    invite_snackbar_success:
      "The invitation was successfully sent to the customer.",
    import_customer: "Import customer",
    import: "Import",
    selected: "selected",
    import_success_snackbar: "Your import was successful.",
    no_customers_dialog_title: "You have no customers you can import.",
  },

  // floating menu - kanban board
  floating_menu: {
    change_status: "Change status",
    deselect_all: "Deselect all ",
  },

  talentPools: {
    created_at: "Created at",
    in_the_talentpool: "in the talent pool",
    talentPools: "Talent pools",
    noTalentPoolsTitle: "You don't have a talent pool yet.",
    noTalentPoolsSubtitle:
      "Create your first talent pool and save suitable profiles for later.",
    createTalentPool: "Create Talent pool",
    addTalentPool: "Add talent pool",
    description: "Description",
    date: "Date",
    created: "Created",
    profiles: "Profiles",
    delete: "Delete",
    editTalentPool: "Edit Talent pool",
    deleteTalentPoolTitle: "Delete Talent pool",
    deleteTalentPoolFirstSubTitle:
      "Are you sure you want to delete this talent pool?",
    deleteTalentPoolSecondSubTitle:
      "The talent pool will be removed along with all the profiles stored in it.",
    successTalentPoolCreated: "The talent pool was successfully created",
    successTalentPoolDeleted: "The talent pool was successfully deleted",
    successTalentPoolEdited: "The talent pool was successfully edited",
    successCandidateAddedInTalentPool:
      "The profile was successfully added to the talent pool",
    successCandidatesAddedInTalentPool:
      "The profiles have been successfully added to the talent pool",
    successAddCandidateToJob:
      "The profile was successfully added to the job and removed from the talent pool",
    isInYourTalentPool: "is in your Talent pool",
    add: "Add",
    addToTalentPool: "Add to Talent pool",
    addToActiveJob: "Add to active job",
    addProfileToJob: " Add profile {{firstname}} {{lastname}} to a job",
    deleteCandidateFromTalentPoolTitle: "Delete profile from talent pool",
    deleteCandidateFromTalentPoolSubTitle:
      "Are you sure you want to delete this profile from the talent pool?",
    noCandidatesInTalentPoolTitle:
      "Your list is empty, you have not yet added any profiles.",
    noCandidatesInTalentPoolSubTitle:
      "You can add profiles to this talent pool that are not currently suitable for your open positions. To do this, go to the applicant list of a job and click on the status in the application and select: Add to talent pool",
  },

  // universal
  active: "Active",
  archived: "Archived",
  draft: "Draft",
  field_max_length_text: "The text length must not exceed 200 characters",
  expired: "Expired",
  aborted: "Canceled",
  type: "Type",
  terms: "Terms & Conditions",
  privacy: "Privacy",
  no_options: "Nothing could be found.",
  resend_link: "Resend link",
  email: "Email",
  valid_email_message: "Email must be a valid email",
  invalid_email_message: "Invalid email address",
  website_valid_url_message: "Website should be a valid URL",
  password_match_message: "Your passwords do not match.",
  accept_terms_text: "Please accept the terms and conditions and the dpa",
  email_exist: "Email exist",
  company_name_exist: "Company name exist",
  application_email_exist: "An application with this email has been submitted",
  password_match_message_try_again:
    "The email and password don't match. Please, try again.",
  cv_text:
    "The option “Upload CV” is not mandatory, but is always offered on the application page",

  // yes no
  yes: "Yes",
  no: "No",
  comming_soon: "Coming soon",
  at: "at",
  delete_document: "Delete document",
  request_documents: "Request documents",
  request_data: "Request data",
  document_dialog:
    "Do you really want to delete the document {{name}}? This process can no longer be undone.",
  showing: "Showing",
  results: "results",

  jobDetails: {
    showMoreBtn: "Show more",
    showLessBtn: "Show less",
  },

  singleJob: {
    jobDetails: {
      titles: {
        jobMedia: "Job media",
        jobDescription: "Job description",
        jobDetails: "Job details",
        applicationProcess: "Application process",
        requiredDocuments: "Required documents",
        additionalQuestions: "Additional questions",
        noAdditionalQuestionsTitle:
          "You do not yet have an additional question.",
        noAdditionalQuestionsSubtitle:
          "Click Edit to add a question for potential applicants.",
      },
      salary: {
        currencies: {
          "(EUR)": "Euro (EUR)",
          "(USD)": "U.S. Dollar (USD)",
          "(CHF)": "Swiss Francs (CHF)",
          "(GBP)": "Pound Sterling (GBP)",
        },
        type: {
          annually: "Annually",
          monthly: "Monthly",
          hourly: "Hourly",
        },
      },
    },
    errors: {
      maxSalaryGreaterThan:
        "Maximum salary must be greater than Minimum salary",
    },
  },
  jobPreview: {
    salary: "{{min}} {{currency}} to {{max}} {{currency}}",
  },

  workflow: {
    addWorkflow: "Add workflow",
    myWorkflows: "My Workflows",
    adjustWorkflow: "Adjust workflow",
    statusOptions: "Status options",
    states: "States",
    addNewState: "Add state",
    newState: "New state",
    defaultWorkflowText: "Set this workflow as default",
    workflowCreatedSnackbar: "Workflow successfully created",
    workflowUpdatedSnackbar: "Workflow successfully edited",
    workflowDeletedSnackbar: "Workflow successfully deleted",
    deleteWorkflow: "Delete workflow",
    deleteWorkflowText: "Are you sure you want to delete this workflow?",
    defaultWorkflow: "Default Workflow",
    setWorkflow: "Set workflow",
    setDefaultWorkflowForJob: "Set workflow as default for this job",
    deleteWorkflowDialogNote:
      "If you delete this status all applications in this status will be moved to the status New",
  },

  analytics: {
    applications: "Applications",
    numberOfApplications: "Number of Applications",
    sourceOfHire: "Applied via",
    timeToHire: "Time to hire",
    today: "Today",
    yesterday: "Yesterday",
    thisWeek: "This Week",
    lastWeek: "Last Week",
    last7Days: "Last 7 Days",
    thisMonth: "This Month",
    lastMonth: "Last Month",
    selectDateRange: "Select Date Range",
    unknown: "Unknown",
    noDataText: "No data are available.",
    changeDateFilter: "Try to change the date range",
    source: {
      linkedin: "LinkedIn",
    },
    defaultStatuses: {
      new: "New",
      in_progress: "In Progress",
      review: "In Progress",
      hired: "Hired",
      rejected: "Rejected",
      active: "Active",
      inactive: "Inactive",
      archived: "Archived",
      draft: "Draft",
    },
    infoText: {
      numberOfApplications:
        "Shows the number of applications per day for the selected job in the selected period.",
      sourceOfHire:
        "Shows the number of applications by channel for the selected job in the selected period. Some job portals do not support tracking and are recorded here as Unknown.",
      timeToHire:
        "Shows the average duration of applications in the respective status. Please note that Rejected and Discontinued are also considered here. These can be selected and deselected by clicking on the status in the legend. ",
    },
  },
};
