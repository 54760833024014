import { TFunction } from "i18next";
import { IInitialColumns } from "./Kanban";
import { TSelectedJobCandidateApplication } from "./ApplicationPage";
import { TJobOwner } from "./SingleJob";
import { DIALOG_TYPE, TListOption } from "./common";
import { IWorkflow } from "./Workflow";

export type TApiGetAllApplicationsByJobId = {
  readonly pagesize?: TApplicationsPagination["pageSize"];
};

export type TApiGetApplicationsPayload = {
  readonly name?: TApplicationsFilters["quickSearch"];
  readonly state?: TApplicationsFilters["status"]["value"];
  readonly jobId?: TApplicationsFilters["appliedTo"]["value"];
  readonly pagenum: TApplicationsPagination["pageNum"];
  readonly pagesize: TApplicationsPagination["pageSize"];
};

export type TApiGetApplicationAnswers = {
  readonly job_id: string;
};

export type TApiGetAllApplicationsPayload = {
  readonly pagesize: TApplicationsPagination["pageSize"];
};

export type TApiUpdateApplicationsStatusPayload = {
  readonly status: string;
};

export interface IApplicationsState {
  readonly filters: TApplicationsFilters;
  readonly pagination: TApplicationsPagination;
  readonly applications: TApplications;
  readonly allApplications: TApplication[] | null;
  readonly selectedApplications: TApplication[];
  readonly view: string;
  readonly error: unknown;
  readonly isUpdateAnswersLoading: boolean;
  readonly isLoading: boolean;
  readonly isListLoading: boolean;
  readonly isOwnersLoading: boolean;
  readonly isDeleteApplicationLoading: boolean;
  readonly selectedJobOwners?: TJobOwner[];
  readonly activityLog?: [];
  readonly candidateAnswers: {
    label: string;
    value: string;
  }[];
  readonly jobUrlKey: string | null;
  readonly jobWorkflow: null | IWorkflow;
  readonly isRequestUnlockLoading: boolean;
  readonly requestedDataList: { title: string; id: string }[];
}

export interface IApplicationsComponent {
  readonly t: TFunction;
  readonly containerMargin?: number;
  readonly dialogType: DIALOG_TYPE | null;
  readonly showPremiumButton?: boolean;
  readonly list: TApplication[];
  readonly jobId: string | null;
  readonly view: IApplicationsState["view"];
  readonly statuses: TListOption[];
  readonly initialColumns: IInitialColumns;
  readonly isListLoading: boolean;
  readonly pageSize: TApplicationsPagination["pageSize"];
  readonly pageNum: TApplicationsPagination["pageNum"];
  readonly applicationsCount: TApplications["totalCount"];
  readonly selectedApplications: TApplication[];
  readonly onDeselectAll: () => void;
  readonly onChangeMultipleStatus: (newStatus: string) => void;
  readonly onItemClick: (application: TSelectedJobCandidateApplication) => void;
  readonly onDeleteApplication: () => void;
  readonly onOpenDialog: (
    params: TApplication | null,
    type: DIALOG_TYPE
  ) => void;
  readonly onCloseDialog: () => void;
  readonly actions: {
    readonly onRemove: (params: TApplication) => void;
    readonly onEdit?: (application: TSelectedJobCandidateApplication) => void;
    readonly onExport?: (applications: TApplication | TApplication[]) => void;
    readonly onChangeStatus?: (data: {
      readonly isTalentPooled: 0 | 1;
      readonly talentPoolTitle: string | null;
      readonly applicationId: string;
      readonly newStatus: string;
      readonly index: string;
    }) => void;
    readonly onDownloadCV: (cvLink: string) => void;
  };
  readonly onSelect: (application: TApplication) => void;
  readonly onPaginate: (pageNum: TApplicationsPagination["pageNum"]) => void;
}

export interface IApplicationsFilters {
  readonly t: TFunction;
  readonly jobTypeFilter: TApplicationsFilters["appliedTo"];
  readonly stateFilter: TApplicationsFilters["status"];
  readonly companyFilter: TApplicationsFilters["company"];
  readonly jobId: string | null;
  readonly quickSearch: string;
  readonly location: string;
  readonly view: IApplicationsState["view"];
  readonly allJobs?: TListOption[];
  readonly allLocations?: TListOption[];
  readonly isAgency: number;
  readonly customers: TListOption[];
  readonly filtersWidth: number;
  readonly cardsApplications: any;
  readonly onOpenSetWorkflowDialog: () => void;
  readonly onChangeFilters: (data: {
    readonly value: string;
    readonly filterType: keyof TApplicationsFilters;
  }) => void;
  readonly onChangeView: (data: IApplicationsState["view"]) => void;
  readonly onOpenApplyJobDialog: () => void;
}

export interface IApplicationsToolbar {
  readonly t: TFunction;
  readonly selectedApplications: TApplication[];
  readonly showAddApplicationButton?: boolean;
  readonly onOpenApplyJobDialog: () => void;
  readonly onExport?: (applications: TApplication | TApplication[]) => void;
}

export interface IApplicationsNoResultComponent {
  readonly bodyText: string;
  readonly titleText: string;
  readonly hasFilters: boolean;
  readonly showPremiumButton?: boolean;
  readonly t: TFunction;
  readonly onResetFilters: () => void;
}

export type TApplicationsFilters = {
  readonly quickSearch: string;
  readonly location: string;
  readonly appliedTo: {
    readonly options: { readonly value: string; readonly label: string }[];
    readonly value: string;
  };
  readonly status: {
    readonly options: { readonly value: string; readonly label: string }[];
    readonly value: string;
  };
  readonly company: {
    readonly options: { readonly value: string; readonly label: string }[];
    readonly value: string;
  };
};

export type TApplications = {
  readonly list: TApplication[];
  readonly selectedJobApplications?: TSelectedJobCandidateApplication[];
  readonly cardsApplications?: any;
  readonly totalCount: string;
};

export type TApplicationsPagination = {
  readonly pageNum: number;
  readonly pageSize: number;
};

export type TApplication = {
  readonly id?: number;
  readonly application_id?: string;
  readonly candidate_id?: string | number;
  readonly email: string;
  readonly firstname: string;
  readonly lastname: string;
  readonly candidate_url_key: string;
  readonly job_id: string;
  readonly company_id: string;
  readonly title: string;
  readonly photo: string;
  readonly job_url_key: string;
  readonly job_created_at: string;
  readonly timestamp: string;
  readonly country: string;
  readonly location: string;
  readonly phone: string | null;
  readonly current_professional_status: string | null;
  readonly highest_degree: string | null;
  readonly salary_expectation: string;
  readonly drivers_license: string;
  readonly languages: string | null;
  readonly skills: string;
  readonly earliest_start_date: string | null;
  readonly status: string;
  readonly workflow_id: number;
  readonly documents: TApplicationDocument[];
};

export type TApplicationDocument = {
  link: string;
  isCV?: number; // TODO should be boolean
  id?: number;
  find(
    predicate: (document: TApplicationDocument) => boolean
  ): TApplicationDocument | undefined;
};

export enum APPLICATION_STATUSES {
  HIRED = "hired",
  NEW = "new",
  REJECTED = "rejected",
  REVIEW = "review",
}
